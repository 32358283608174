import React from "react";
import "./aboutsection.css";
import StringData from "../../String.json";

const QualityProgram = () => {
  const linearGradient =
    "linear-gradient(148deg, rgba(28,28,28,0.80) 0%, rgba(28,28,28,0.80) 100%)";
  const imageUrl = `url('${StringData.aboutsection.backgroundImage}')`;
  const backgroundImg = {
    background: `${linearGradient},${imageUrl} no-repeat center center / cover`,
  };
  return (
    <>
      <div className="container-fluid about-main" style={backgroundImg}>
        <div className="container about-section">
          <div className="row about-content d-flex justify-content-center align-content-center">
            <div className="col-md-8 text-content pt-5 pb-5 d-flex flex-column gap-4 ">
              <h1 className="about-title">About Us</h1>
              <p className="about-para">
                <b>{StringData.aboutsection.company_name}</b>
                {StringData.aboutsection.description}
              </p>
            </div>

            {/* mission and vision */}

            <div className="container text-content pt-5 pb-5 d-flex justify-content-between flex-wrap">
              <div className="col-md-5">
                <h3 className="text-white text-start">Mission</h3>
                <p className="about-para text-start">
                  Our mission is to enhance food security and consumer welfare
                  by promoting food production, processing, value addition, and
                  literacy, while protecting consumer interests and meeting the
                  evolving demands of the market.
                </p>
              </div>
              <div className="col-md-6">
                <h3 className="text-white text-start">Vision</h3>
                <p className="about-para text-start">
                  To become a leading force in shaping a food-secure Nepal by
                  driving innovation in agriculture, supporting sustainable
                  practices, and enhancing the entire food supply chain. We
                  envision a future where every citizen has access to safe,
                  nutritious, and affordable food, and the country's food sector
                  thrives in harmony with the principles of sustainability and
                  consumer protection.
                </p>
              </div>
            </div>

            {/* Goals */}

            <div className="col-md-8 w-100" style={{marginBottom:"200px"}}>
              <h3 className="text-white text-start">Goals</h3>
              <ol className="about-para text-start">
                <li>
                  To Promote sustainable agricultural practices: By focusing on
                  the production, processing, and value addition of high-quality
                  food products.
                </li>
                <li>
                  To Support consumer welfare: By ensuring food safety, quality,
                  and transparency in the market.
                </li>
                <li>
                  To promote food literacy campaigns and raise awareness about
                  nutrition, sustainability, and consumer rights.
                </li>
                <li>To contribute to the country's sustainable development</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QualityProgram;
