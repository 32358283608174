import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../Home/Home";
import ServicePage from "../Pages/ServicePage/ServicePage";
// import PortfolioPage from "../PortfolioPage/PortfolioPage";
// import BlogPage from "../BlogPage/BlogPage";
import NoPage from "../NoPage/NoPage";
// import BlogPageOne from "../BlogPage/BlogPageList/BlogPageOne";
// import BlogPageTwo from "../BlogPage/BlogPageList/BlogPageTwo";
// import BlogPageThree from "../BlogPage/BlogPageList/BlogPageThree";
import AboutPage from "../Pages/AboutPage/AboutPage";

const BodyRoute = () => {
  return (
    <>
      <div className="bodysection">
        <Routes>
          <Route path="/" exact element={<Home />}></Route>
          <Route path="/services" exact element={<ServicePage />}></Route>
          {/* <Route path="/portfolio" exact element={<PortfolioPage />}></Route>
          <Route path="/blog" exact element={<BlogPage />}></Route>
          <Route path="/blog/1" exact element={<BlogPageOne />}></Route>
          <Route path="/blog/2" exact element={<BlogPageTwo />}></Route>
          <Route path="/blog/3" exact element={<BlogPageThree />}></Route> */}
          <Route path="/about" exact element={<AboutPage />}></Route>

          <Route path="*" exact element={<NoPage />}></Route>
        </Routes>
      </div>
    </>
  );
};

export default BodyRoute;
