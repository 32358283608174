import React from "react";
import "./servicesection.css";
import ServiceCard from "./ServiceCard";
import StringData from "../../String.json";

const ServiceSection = () => {
  return (
    <>
      <div className="container-fluid service-main">
        <div className="container">
          <div className="top-icon">
            <div className="hr-line"></div>
            <div className="circle">
              <img src={StringData.servicesection.icon} alt="seeds" />
            </div>
            <div className="hr-line"></div>
          </div>
          <div className="service-content">
            <div className="service-text">
              <h1>Our Best Services</h1>
              <p>{StringData.servicesection.description}</p>
            </div>
            <div className="service-card mt-5">
              <ServiceCard />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceSection;
