import React, { useState, useEffect } from "react";
import "./guarantee.css";

import StringData from "../../String.json";

const GuaranteeSection = () => {
  const [data, setData] = useState(StringData.guaranteesection.guaranteedata);
  useEffect(() => {
    if (
      StringData &&
      StringData.guaranteesection &&
      StringData.guaranteesection.guaranteedata
    ) {
      setData(StringData.guaranteesection.guaranteedata);
    }
  }, [data]);
  return (
    <>
      <div className="container-fluid">
        <div className="row guarantee-content">
          <div className="col-md-6 p-0 guarantee-img">
            <img
              src={StringData.guaranteesection.guaranteeImage}
              alt="seed guarantee"
            />
          </div>

          <div className="col-md-6 p-0 guarantee-text text-start p-5">
            {data.map((items) => (
              <div key={items.guaranteeId} className="text-card d-flex gap-4">
                <div className="guarantee-icon">
                  <img src={items.icon} alt={items.title} />
                </div>
                <div className="guarantee-para">
                  <h4 className="fw-bold">{items.title}</h4>
                  <p>{items.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default GuaranteeSection;
