import React from "react";
import "./herosection.css";
import StringData from "../../String.json";

const HeroSection = () => {
  const linearGradient =
    "linear-gradient(148deg, rgba(0,58,21,0.8464635854341737) 0%, rgba(0,58,21,1) 100%)";
  const backgroundImageUrl = `url('${StringData.herosection.backgroundImage}')`;
  const backgroundImg = {
    background: `${linearGradient},${backgroundImageUrl} no-repeat center center / cover`,
  };
  return (
    <>
      <div className="container-fluid hero-main" style={backgroundImg}>
        <div className="container hero-section">
          <div className="row hero-content">
            <div className="col-md-6 content-left d-flex flex-column gap-4 justify-content-start align-content-center">
              <h1 className="hero-title">
                {StringData.herosection.title} {""}
                <span className="company-name">
                  {StringData.herosection.company_name}
                </span>
              </h1>
              <p className="hero-para">{StringData.herosection.description}</p>
              <a href="#product-main">
                <button type="button" className="hero-cta">
                  {StringData.herosection.cta}
                </button>
              </a>
            </div>
            <div className="col-md-6 content-right">
              <img
                src={StringData.herosection.heroImage}
                alt="seed bank limited"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
