import React from "react";
import GoToTop from "../../GoToTop/GoToTop";
import AboutSection from "../../Home/AboutSection/AboutSection"

const AboutPage = () => {
  return (
    <>
      <div className="about-page">
        <AboutSection/>
      </div>
      
      <GoToTop />
    </>
  );
};

export default AboutPage;
