import React from "react";
import "./initiationcard.css";
import StringData from "../../String.json";
const InitiationCard = () => {
  return (
    <>
      <div className="container initiation-card">
        <div className="row initiation-content">
          <div className="col-md-6 seedtest-img">
            <img
              src={StringData.initiationcard.cardImage}
              alt="Seed quality control center"
            />
          </div>
          <div className="col-md-6 seedtest-text">
            <h2>{StringData.initiationcard.title}</h2>
            <p>{StringData.initiationcard.description}</p>
            {/* <a href="learnmore">Learn More</a> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default InitiationCard;
