import React from 'react'
import GoToTop from '../../GoToTop/GoToTop'
import ServiceSection from '../../Home/ServiceSection/ServiceSection'

const ServicePage = () => {
  return (
    <>
    <div className='service-page mb-5'>
      <ServiceSection/>
      </div>
      <GoToTop/>
    </>
    
  )
}

export default ServicePage
