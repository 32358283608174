import React, { useState, useEffect } from "react";
import "./servicecard.css";
import AOS from "aos";
import "aos/dist/aos.css";
import StringData from "../../String.json";

const ServiceCard = () => {
  const [hoveredCard, setHoveredCard] = useState(null);
  const [data, setData] = useState(StringData.servicesection.servicedata);
  useEffect(() => {
    if (
      StringData &&
      StringData.servicesection &&
      StringData.servicesection.servicedata
    ) {
      setData(StringData.servicesection.servicedata);
    }
    AOS.init({ duration: 1000 }); // Set duration to 1000ms (1 second)
  }, [data]);
  return (
    <>
      <div className="container service-card mb-5">
        <div className="row d-flex gap-5 justify-content-center align-items-top ">
          {data.map((items) => (
            <div
              key={items.cardId}
              class="col-3 p-0 pb-2 card"
              style={{ height: "auto" }}
              onMouseEnter={() => setHoveredCard(items.cardId)}
              onMouseLeave={() => setHoveredCard(null)}
              data-aos="fade-up"
              data-aos-easing="ease"
            >
              <img
                src={
                  hoveredCard === items.cardId
                    ? items.cardImageOnHover
                    : items.cardImage
                }
                class="card-img-top object-fit-cover"
                alt={items.title}
                style={{ height: "250px" }}
              />
              <div class="card-body text-start" style={{ height: "auto" }}>
                <h5 class="card-title fw-bold">{items.title}</h5>
                <p class="card-text">{items.para}</p>
                {/* <a href="#Learnmore">Learn More</a> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ServiceCard;
