import React from "react";
import HeroSection from "./HeroSection/HeroSection";
import InitiationCard from "./InitiationCard/InitiationCard";
import ProductSection from "./ProductSection/ProductSection";
import AboutSection from "./AboutSection/AboutSection";
import ServiceSection from "./ServiceSection/ServiceSection";
import GuaranteeSection from "./GuaranteeSection/GuaranteeSection";
import GallerySection from "./GallerySection/GallerySection";

const Home = () => {
  return (
    <>
      <HeroSection />
      <InitiationCard />
      <ProductSection />
      <AboutSection />
      <ServiceSection />
      <GuaranteeSection />
      <GallerySection />
    </>
  );
};

export default Home;
